import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';
import Menu from "./service/Menu";

const routes = [
    
    {
        path: '/dashboard',
        name: 'app',
        component: App,
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import('./components/Dashboard.vue')
            },
            // {
            //     path: '/dashboard',
            //     name: 'dashboard',
            //     component: () => import('./components/Dashboard_new.vue')
            // },
            {
                path: '/userProfile',
                name: 'userProfile',
                component: () => import('./pages/UserProfile.vue'),
                
            },

            {
                path: '/profile',
                meta: {
                    authRequires: true
                },
                name: 'profile',
                component: () => import('./pages/profile.vue')
            },
           
            
            {
                path: '/usermaster',
                meta: {
                    authRequires: true
                },
                name: 'usermaster',
                component: () => import('./pages/UserMaster.vue')
            },

            {
                path: '/brandmaster',
                meta: {
                    authRequires: true
                },
                name: 'brandmaster',
                component: () => import('./pages/BrandMaster.vue')
            },
            {
                path: '/brandmaster_new',
                meta: {
                    authRequires: true
                },
                name: 'brandmaster_new',
                component: () => import('./pages/BrandMaster2.vue')
            },  
            {
                path: '/productmaster',
                meta: {
                    authRequires: true
                },
                name: 'productmaster',
                component: () => import('./pages/ProductMaster.vue')
            }, 
            {
                path: '/bannermaster',
                meta: {
                    authRequires: true
                },
                name: 'bannermaster',
                component: () =>import('./pages/BannerMaster.vue')
            },
            {
                path: '/slidermaster',
                meta: {
                    authRequires: true
                },
                name: 'slidemaster',
                component: () =>import('./pages/SlideMaster.vue')
            },
            {
                path: '/contact',
                meta: {
                    authRequires: true
                },
                name: 'contact',
                component: () =>import('./pages/ContactList.vue')
            },
            {
                path: '/productType',
                meta: {
                    authRequires: true
                },
                name: 'productType',
                component: () =>import('./pages/ProductType.vue')
            },
            {
                path: '/category',
                meta: {
                    authRequires: true
                },
                name: 'category',
                component: () =>import('./pages/CategoryList.vue')
            },
            {
                path: '/collection',
                meta: {
                    authRequires: true
                },
                name: 'collection',
                component: () =>import('./pages/CollectionList.vue')
            },
            {
                path: '/todays_deal',
                meta: {
                    authRequires: true
                },
                name: 'todays_deal',
                component: () =>import('./pages/TodaysDealList.vue')
            },
            {
                path: '/ecommerce_menu',
                meta: {
                    authRequires: true
                },
                name: 'ecommerce_menu',
                component: () =>import('./pages/ECommerceMenu.vue')
            },
            {
                path: '/ecommerce_menu2',
                meta: {
                    authRequires: true
                },
                name: 'ecommerce_menu2',
                component: () =>import('./pages/ECommerceMenu2.vue')
            },
            {
                path: '/product_add',
                meta: {
                    authRequires: true
                },
                name: 'product_add',
                component: () => import('./pages/product_add.vue')
            },
            {
                path: '/product_edit',
                meta: {
                    authRequires: true
                },
                name: 'product_edit',
                component: () => import('./pages/product_edit.vue')
            },       
            {
                path: '/clientmaster',
                meta: {
                    authRequires: true
                },
                name: 'clientmaster',
                component: () => import('./pages/ClientMaster.vue')
            },
            
            
            
            
            {
                path: '/role_master',
                meta: {
                    authRequires: true
                },
                name: 'role_master',
                component: () => import('./pages/role_master.vue')
            },
            {
                path: '/menu_master',
                meta: {
                    authRequires: true
                },
                name: 'menu_master',
                component: () => import('./pages/menu_master.vue')
            },
            
            {
                path: '/user_add',
                meta: {
                    authRequires: true
                },
                name: 'user_add',
                component: () => import('./pages/user_add.vue')
            },
            {
                path: '/user_edit',
                meta: {
                    authRequires: true
                },
                name: 'user_edit',
                component: () => import('./pages/user_edit.vue')
            },
            
            {
                path: '/client_add',
                meta: {
                    authRequires: true
                },
                name: 'client_add',
                component: () => import('./pages/client_add.vue')
            },
            
            {
                path: '/TestingUI',
                name: 'TestingUI',
                component: () => import('./pages/TestingUI.vue'),
               
            },
            {
                path: '/clientProfile',
                name: 'clientProfile',
                component: () => import('./pages/ClientProfile.vue'),
               
            },
            {
                path: '/formlayout',
                meta: {
                    authRequires: true
                },
                name: 'formlayout',
                component: () => import('./components/FormLayoutDemo.vue')
            },
            {
                path: '/input',
                meta: {
                    authRequires: true
                },
                name: 'input',
                component: () => import('./components/InputDemo.vue')
            },
            {
                path: '/floatlabel',
                meta: {
                    authRequires: true
                },
                name: 'floatlabel',
                component: () => import('./components/FloatLabelDemo.vue')
            },
            {
                path: '/invalidstate',
                meta: {
                    authRequires: true
                },
                name: 'invalidstate',
                component: () => import('./components/InvalidStateDemo.vue')
            },
            {
                path: '/button',
                meta: {
                    authRequires: true
                },
                name: 'button',
                component: () => import('./components/ButtonDemo.vue')
            },
            {
                path: '/table',
                meta: {
                    authRequires: true
                },
                name: 'table',
                component: () => import('./components/TableDemo.vue'),
                
            },
            
            {
                path: '/table',
                meta: {
                    authRequires: true
                },
                name: 'table',
                component: () => import('./components/TableDemo.vue'),
                
            },
            
            
            {
                path: '/map',
                meta: {
                    authRequires: true
                },
                name: 'map',
                component: () => import('./pages/MapDemo.vue'),
                
            },
            {
                path: '/list',
                meta: {
                    authRequires: true
                },
                name: 'list',
                component: () => import('./components/ListDemo.vue')
            },
            {
                path: '/tree',
                meta: {
                    authRequires: true
                },
                name: 'tree',
                component: () => import('./components/TreeDemo.vue')
            },
            {
                path: '/panel',
                meta: {
                    authRequires: true
                },
                name: 'panel',
                component: () => import('./components/PanelsDemo.vue')
            },
            {
                path: '/overlay',
                meta: {
                    authRequires: true
                },
                name: 'overlay',
                component: () => import('./components/OverlayDemo.vue')
            },
            {
                path: '/media',
                meta: {
                    authRequires: true
                },
                name: 'media',
                component: () => import('./components/MediaDemo.vue')
            },
            {
                path: '/menu',
                component: () => import('./components/MenuDemo.vue'),
                children: [
                    {
                        path: '',
                        component: () => import('./components/menu/PersonalDemo.vue')
                    },
                    {
                        path: '/menu/seat',
                        component: () => import('./components/menu/SeatDemo.vue')
                    },
                    {
                        path: '/menu/payment',
                        component: () => import('./components/menu/PaymentDemo.vue')
                    },
                    {
                        path: '/menu/confirmation',
                        component: () => import('./components/menu/ConfirmationDemo.vue')
                    },
                 
                ],
            },
            {
                path: '/messages',
                meta: {
                    authRequires: true
                },
                name: 'messages',
                component: () => import('./components/MessagesDemo.vue')
            },
            {
                path: '/file',
                meta: {
                    authRequires: true
                },
                name: 'file',
                component: () => import('./components/FileDemo.vue')
            },
            {
                path: '/chart',
                meta: {
                    authRequires: true
                },
                name: 'chart',
                component: () => import('./components/ChartDemo.vue')
            },
            {
                path: '/misc',
                meta: {
                    authRequires: true
                },
                name: 'misc',
                component: () => import('./components/MiscDemo.vue')
            },
            {
                path: '/crud',
                meta: {
                    authRequires: true
                },
                name: 'crud',
                component: () => import('./pages/CrudDemo.vue')
            },
            
            
            {
                path: '/blocks',
                meta: {
                    authRequires: true
                },
                name: 'blocks',
                component: () => import('./components/BlocksDemo.vue')
            },
            {
                path: '/icons',
                meta: {
                    authRequires: true
                },
                name: 'icons',
                component: () => import('./components/IconsDemo.vue')
            }
            
        ]
    },
    {
        path: '/',
        name: 'pph_home',
        component: () => import('./pages/pph_home.vue')
    },
    {
        path: '/:404page(.*)*',
        name: '404page',
        component: () => import('./pages/404.vue'),
        
    },
    
    
    {
        path: '/listing',
        name: 'pph_listing',
        component: () => import('./pages/pph_listing.vue'),
        props:true
    },


    {
        path: '/proDetails/:title',
        name: 'pphprodetails',
        component: () => import('./pages/pph_prodetails.vue'),
        props:true
    },

    {
        path: '/pphbrand',
        name: 'pphbrand',
        component: () => import('./pages/pph_brand.vue'),
        props:true
    },

    {
        path: '/pphlogin',
        name: 'pphlogin',
        component: () => import('./pages/loginpph.vue')
    },
    {
        path: '/About',
        name: 'About',
        component: () => import('./pages/About.vue')
    },
    {
        path: '/Roadmap',
        name: 'Roadmap',
        component: () => import('./pages/Roadmap.vue')
    },
    {
        path: '/Term_Condition',
        name: 'Term_Condition',
        component: () => import('./pages/Term_Condition.vue')
    },
    {
        path: '/PrivacyPolicy',
        name: 'PrivacyPolicy',
        component: () => import('./pages/PrivacyPolicy.vue')
    },
    {
        path: '/landing',
        name: 'landing',
        component: () => import('./pages/LandingDemo.vue')
    },
    {
        path: '/Login',
        name: 'Login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});


//var page_name = this.$route.name;
var user_name = localStorage.getItem("user_key");
var Menu_master = null;
var autoValue =null;
Menu_master = new Menu();

//router.beforeEach((to, from, next) => {
   
    // if (to.meta.authRequires) 
    // {  
        
    //     if (user_name == "admin") 
    //     { 
    //     Menu_master.get_menuAdmin().then((Admin_data) => {
    //     autoValue = Admin_data;
    //     if (autoValue.indexOf(to.name.toString()) !== -1) {console.log(user_name);next();} else {console.log(user_name);next('/access');}
    //     });
      
    //     } 
    //     else if (user_name == "teacher") 
    //     { 
    //     Menu_master.get_menuTeacher().then((Teacher_data) => {
    //     autoValue = Teacher_data;
    //     if (autoValue.indexOf(to.name.toString()) !== -1) {console.log(user_name);next();} else {console.log(user_name);next("/access");}
    //      });
      
    //     } 
    //     else if (user_name == "assteacher") 
    //     { 
    //     Menu_master.get_menuassistantTeacher().then((Ass_Teacher_data) => {
    //     autoValue = Ass_Teacher_data;
        
    //     if (autoValue.indexOf(to.name.toString()) !== -1) {console.log(user_name); next();} else {console.log(user_name);next("/access");}
    //     });
      
    //     } 
    //     else if (user_name == "network")
    //     { 
    //     Menu_master.get_menunetwork().then((Network_data) => {
    //     autoValue = Network_data;
    //     if (autoValue.indexOf(to.name.toString()) !== -1) {console.log(user_name);next();} else {console.log(user_name);next("/access");}
    //     });
     
    //     }
    //     else if (user_name == "student")
    //     { 
    //     Menu_master.get_menuStudent().then((Student_data) => {
    //     autoValue = Student_data;
    //     if (autoValue.indexOf(to.name.toString()) !== -1) {console.log(user_name);next();} else {console.log(user_name);next("/access");}
    //     });
     
    //     }


    // }
    // else{

    // next();
    // }

    //next();

//});

export default router;
