 var apiurltravel="https://5rwvx4smik.execute-api.ap-south-1.amazonaws.com/dev/api/";
 var apiurltravel_new="https://0vahr4y5k1.execute-api.ap-south-1.amazonaws.com/dev/api/";
 
//  var apiurltravel      =   "http://localhost:3000/dev/api/";
//  var apiurltravel_new      =   "http://localhost:3000/dev/api/";

import axios from 'axios'


import { authHeader } from '../helpers/auth-header';

let header = authHeader()

export default {
    
    login(data)
    {
        return axios.post(apiurltravel + "user/userlogin", JSON.stringify(data),  header)
    },
    vehiclelist(data)
    {
        return axios.post(apiurltravel + "vehicles/adminVehicleList", JSON.stringify(data),  header)
    },
    dashboardRideData(data)
    {
        return axios.post(apiurltravel + "user/adminDashboardRideData", JSON.stringify(data),  header)
    },
    // dashboardcount(data)
    // {
    //     return axios.post(apiurltravel + "user/adminDashboardData", JSON.stringify(data),  header)
    // },
    // userlist(data)
    // {
    //     return axios.post(apiurltravel + "user/userList", JSON.stringify(data),  header)
    // },
    
    sosAlertMaster(data)
    {
        return axios.post(apiurltravel + "user/adminuserNotification", JSON.stringify(data),  header)
    },
    todaysTravelListDashboard(data)
    {//Data/subjectlist
        return axios.post(apiurltravel + "userRide/todaysTravelListDashboard",JSON.stringify(data),   header)
    },
    adminUserProfile(data)
    {//Data/subjectlist
        return axios.post(apiurltravel + "user/adminUserProfile",JSON.stringify(data),   header)
    },
    vehicleDriverList(data)
    {
        return axios.post(apiurltravel + "vehicles/vehicleDriverList",JSON.stringify(data),   header)
    },
    searchDriverVehicleAdd(data)
    {
        return axios.post(apiurltravel + "user/searchDriverVehicleAdd",JSON.stringify(data),   header)
    },
    searchGroupVehicleAdd(data)
    {
        return axios.post(apiurltravel + "user/searchGroupVehicleAdd",JSON.stringify(data),   header)
    },
    addDriverVehicle(data)
    {
        return axios.post(apiurltravel + "vehicles/addDriverVehicle",JSON.stringify(data),   header)
    },
    activateVehicleDriver(data)
    {
        return axios.post(apiurltravel + "userRide/activateVehicleDriver",JSON.stringify(data),   header)
    },
    rideMaster(data)
    {
        return axios.post(apiurltravel + "user/userRideMaster",JSON.stringify(data),   header)
    },
    
    adminsendpushnotification(data)
    {
        return axios.post(apiurltravel + "user/adminsendpushnotification", JSON.stringify(data),  header)
    },
    superset_login()
    {
        return axios.post('http://3.110.177.178:8088/api/v1/security/login',JSON.stringify({  "password": "admin",
        "provider": "db",
        "refresh": true,
        "username": "admin"}),header)
    },
    superset_api(data)
    {
        return axios.post(apiurltravel+"user/getsupersettoken", JSON.stringify(data),  header);       
           
    } ,
    group_list(data)
    {
        return axios.post(apiurltravel+"user/groupList", JSON.stringify(data),  header); 
    },
    delete_group_master(data)
    {
        return axios.post(apiurltravel+"user/deletegroupmaster", JSON.stringify(data),  header)
    },
    geocodelats(data)
    {
        return axios.post(apiurltravel+"user/geocodelats", JSON.stringify(data),  header);
    },
    geocodelocationdetails(data)
    {
        return axios.post(apiurltravel+"user/geocodelocationdetails", JSON.stringify(data),  header);
    },
    addgroup(data)
    {
        return axios.post(apiurltravel+"user/addgroup", JSON.stringify(data),  header)
    },
    editgroup(data)
    {
        return axios.post(apiurltravel+"user/edit_group", JSON.stringify(data),  header)
    },
    groupUserListget(data)
    {
        return axios.post(apiurltravel+"vehicles/groupUserList", JSON.stringify(data),  header)
    },
    groupVehicleListget(data)
    {
        return axios.post(apiurltravel+"vehicles/groupVehicleList", JSON.stringify(data),  header)
    },
    addUsersToGroup(data)
    {
        return axios.post(apiurltravel+"vehicles/addUsersToGroup", JSON.stringify(data),  header)
    },
    mappedVehcielGroup(data)
    {
        return axios.post(apiurltravel+"vehicles/mappedVehcielGroup", JSON.stringify(data),  header)
    },
    unmappedVehcielGroup(data)
    {
        return axios.post(apiurltravel+"vehicles/unmappedVehcielGroup", JSON.stringify(data),  header)
    },
    add_group_stops(data)
    {
        return axios.post(apiurltravel+"user/add_group_stops", JSON.stringify(data),  header)
    },
    delete_group_stops(data)
    {
        return axios.post(apiurltravel+"user/delete_group_stops", JSON.stringify(data),  header)
    },
    getMenuList(data)
    {
        return axios.post(apiurltravel+"user/getMenuList", JSON.stringify(data),  header)
    },
    getRoleList(data)
    {
        return axios.post(apiurltravel+"user/getRoleList", JSON.stringify(data),  header)
    },
    addrole(data)
    {
        return axios.post(apiurltravel +"user/addrole", JSON.stringify(data),  header)
    },
    editrole(data)
    {
        return axios.post(apiurltravel +"user/editrole", JSON.stringify(data),  header)
    },
    deleterolemaster(data)
    {
        return axios.post(apiurltravel +"user/deleterole", JSON.stringify(data),  header)
    },
    getMenuListMaster(data)
    {
        return axios.post(apiurltravel+"user/getMenuListMaster", JSON.stringify(data),  header)
    },
    addmenu(data)
    {
        return axios.post(apiurltravel +"user/addmenu", JSON.stringify(data),  header)
    },
    editmenu(data)
    {
        return axios.post(apiurltravel +"user/editmenu", JSON.stringify(data),  header)
    },
    deletemenumaster(data)
    {
        return axios.post(apiurltravel +"user/deletemenu", JSON.stringify(data),  header)
    },
    saveRoleMenuMapping(data)
    {
        return axios.post(apiurltravel+"user/saveRoleMenuMapping", JSON.stringify(data),  header)
    },
    user_rolelist(data)
    {
        return axios.post(apiurltravel+"user/user_rolelist", JSON.stringify(data),  header)
    },
    addclient(data)
    {
        return axios.post(apiurltravel+"user/addclient", JSON.stringify(data),  header)
    },
    editclient(data)
    {
        return axios.post(apiurltravel+"user/editclient", JSON.stringify(data),  header)
    },
    edituser(data)
    {
        return axios.post(apiurltravel+"user/edituser", JSON.stringify(data),  header)
    },
    regUser(data)
    {
        return axios.post(apiurltravel+"user/regUser", JSON.stringify(data),  header)
    },
    clientdepartmentlist(data)
    {
        return axios.post(apiurltravel+"clientdepartment/clientdepartmentlist", JSON.stringify(data),  header)
    },
    clientDepartmentAdd(data)
    {
        return axios.post(apiurltravel+"clientdepartment/clientDepartmentAdd", JSON.stringify(data),  header)
 
    },
    clientDepartmentUpdate(data)
    {
        return axios.post(apiurltravel+"clientdepartment/clientDepartmentUpdate", JSON.stringify(data),  header)

    },
    client_DepartmentDelete(data)
    {
        return axios.post(apiurltravel+"clientdepartment/clientDepartmentDelete", JSON.stringify(data),  header)

    },
    groupList(data)
    {
        return axios.post(apiurltravel+"user/groupList", JSON.stringify(data),  header)

    },
    clientDepartmentMappGroup(data)
    {
        return axios.post(apiurltravel+"clientdepartment/clientDepartmentMappGroup", JSON.stringify(data),  header)

    },
    // getclientist(data)
    // {
    //     return axios.post(apiurltravel+"user/getclientist", JSON.stringify(data),  header)
    // },
    // department_grouplist(data)
    // {
    //     return axios.post(apiurltravel+"user/department_group_list", JSON.stringify(data),  header)
    // },

    editvehicle(data)
    {   
        return axios.post(apiurltravel+"vehicles/vehicleEdit", JSON.stringify(data),  header)
    },
    addvehicle(data)
    {   
        return axios.post(apiurltravel+"vehicles/vehicleReg", JSON.stringify(data),  header)
    },
    vehicle_type_master(data)
    {   
        return axios.post(apiurltravel+"vehicles/get_vehicle_type_master", JSON.stringify(data),  header)
    },
    vehicle_list_by_client_id(data)
    {   
        return axios.post(apiurltravel+"vehicles/vehicle_list_by_client_id", JSON.stringify(data),  header)
    },
    searchhireVehicleAdd(data)
    {   
        return axios.post(apiurltravel+"user/searchhireVehicleAdd", JSON.stringify(data),  header)
    },
    

    uploadFile(data)
    {
       return axios.post(apiurltravel + "getSignedUrlsgb/getSignedURL", JSON.stringify(data),  header)
    },

    HireVehicleList(data)
    {
       return axios.post(apiurltravel + "vehicles/HireVehicleList", JSON.stringify(data),  header)
    },

    vehicles_group_list(data)
    {
       return axios.post(apiurltravel + "vehicles/vehicles_group_list", JSON.stringify(data),  header)
    },

    unmap_vehicle_route(data)
    {
       return axios.post(apiurltravel + "vehicles/unmap_vehicle_route", JSON.stringify(data),  header)
    },
    get_client_type_master(data)
    {
       return axios.post(apiurltravel + "vehicles/get_client_type_master", JSON.stringify(data),  header)
    },
    clientDashboard(data)
    {
       return axios.post(apiurltravel + "user/clientDashboardData", JSON.stringify(data),  header)
    },
    fmDashboardData(data)
    {
       return axios.post(apiurltravel + "user/fmDashboardData", JSON.stringify(data),  header)
    },
    ServiceList(data)
    {
       return axios.post(apiurltravel + "vehicles/UserServiceList", JSON.stringify(data),  header)
    },
    serviceRequestList(data)
    {
       return axios.post(apiurltravel + "user/serviceRequestList", JSON.stringify(data),  header)
    },
    deleteservicedata(data)
    {
       return axios.post(apiurltravel + "vehicles/deleteservice", JSON.stringify(data),  header)
    },
    UserServicereqList(data)
    {
       return axios.post(apiurltravel + "vehicles/UserServicerequestList", JSON.stringify(data),  header)
    },
    get_RideData(data)
    {
       return axios.post(apiurltravel + "user/getRideDataLive", JSON.stringify(data),  header)
    },
    bulkvehicleupload_save(data)
    {
       return axios.post(apiurltravel + "vehicles/bulkvehicleupload", JSON.stringify(data),  header)
    },
    bulkuserupload_save(data)
    {
       return axios.post(apiurltravel + "user/bulkuserupload", JSON.stringify(data),  header)
    },
    deletevehicle(data)
    {
       return axios.post(apiurltravel + "vehicles/vehicleDelete", JSON.stringify(data),  header)
    },
    RoleGroupMasterList(data)
    {
       return axios.post(apiurltravel + "user/RoleGroupMasterList", JSON.stringify(data),  header)
    },

    departmentVehicleListget(data)
    {
        return axios.post(apiurltravel+"vehicles/departmentVehicleList", JSON.stringify(data),  header)
    },
    mappedVehcielDepartment(data)
    {
        return axios.post(apiurltravel+"vehicles/mappedVehcielDepartment", JSON.stringify(data),  header)
    },
    getMediaType(data)
    {
        return axios.post(apiurltravel+"media/GetMediaType", JSON.stringify(data),  header)
    },
    getMediaList(data)
    {
        return axios.post(apiurltravel+"media/GetMediaList", JSON.stringify(data),  header)
    },
    AddMedia(data)
    {
        return axios.post(apiurltravel+"media/AddMedia", JSON.stringify(data),  header)
    },
    EditMedia(data)
    {
        return axios.post(apiurltravel+"media/EditMedia", JSON.stringify(data),  header)
    },
    DeleteMedia(data)
    {
        return axios.post(apiurltravel+"media/DeleteMedia", JSON.stringify(data),  header)
    },
    upload_to_AWS(data)
    {
         return axios.post(apiurltravel+"getSignedUrlsgb/getSignedURL", JSON.stringify(data),  header);
    },
    //------------------------------------------------------------------PPH--------------------------------------------------------------------------------------
    GetBrandsList(data)
    {
         return axios.post(apiurltravel+"pphHome/GetBrandsList", JSON.stringify(data),  header);
    },
    GetProductTypesList(data)
    {
         return axios.post(apiurltravel+"pphHome/GetProductTypeList", JSON.stringify(data),  header);
    },
    GetBannerList(data)
    {
         return axios.post(apiurltravel+"pphHome/GetSliderList", JSON.stringify(data),  header);
    },
    GetProductList(data)
    {
         return axios.post(apiurltravel+"pphHome/GetProductList", JSON.stringify(data),  header);
    },
    GetSliderList(data)
    {
         return axios.post(apiurltravel+"pphHome/GetSliderList", JSON.stringify(data),  header);
    },
    GetCategoriesList(data)
    {
         return axios.post(apiurltravel+"pphHome/GetCategoriesList", JSON.stringify(data),  header);
    },
    GetContactList(data)
    {
         return axios.post(apiurltravel+"pphHome/GetContactList", JSON.stringify(data),  header);
    },
    GetAdsBannerList(data)
    {
         return axios.post(apiurltravel+"pphHome/GetAdsBannerList", JSON.stringify(data),  header);
    },
    GetCollection(data)
    {
         return axios.post(apiurltravel+"pphHome/GetCollectionMaster", JSON.stringify(data),  header);
    },
    GetTodaysDeal(data)
    {
         return axios.post(apiurltravel+"pphHome/GetTodaysDeal", JSON.stringify(data),  header);
    },
    GetEcommerceMenu(data)
    {
         return axios.post(apiurltravel+"pphHome/GetEcommerceMenu", JSON.stringify(data),  header);
    },
    GetBrands(data)
    {
         return axios.post(apiurltravel+"pphHome/GetBrands", JSON.stringify(data),  header);
    },
    UpdateBrand(data)
    {
         return axios.post(apiurltravel+"pphHome/UpdateBrand", JSON.stringify(data),  header);
    },
    AddBrand(data)
    {
         return axios.post(apiurltravel+"pphHome/AddBrand", JSON.stringify(data),  header);
    },
    DeleteBrand(data)
    {
         return axios.post(apiurltravel+"pphHome/DeleteBrand", JSON.stringify(data),  header);
    },
    GetAdsBanner(data)
    {
         return axios.post(apiurltravel+"pphHome/GetAdsBanner", JSON.stringify(data),  header);
    },
    UpdateBanner(data)
    {
         return axios.post(apiurltravel+"pphHome/UpdateBanner", JSON.stringify(data),  header);
    },
    AddBanner(data)
    {
         return axios.post(apiurltravel+"pphHome/AddBanner", JSON.stringify(data),  header);
    },
    DeleteBanner(data)
    {
         return axios.post(apiurltravel+"pphHome/DeleteBanner", JSON.stringify(data),  header);
    },
    GetSlider(data)
    {
         return axios.post(apiurltravel+"pphHome/GetSlider", JSON.stringify(data),  header);
    },
    UpdateSlider(data)
    {
         return axios.post(apiurltravel+"pphHome/UpdateSlider", JSON.stringify(data),  header);
    },
    AddSlider(data)
    {
         return axios.post(apiurltravel+"pphHome/AddSlider", JSON.stringify(data),  header);
    },
    DeleteSlider(data)
    {
         return axios.post(apiurltravel+"pphHome/DeleteSlider", JSON.stringify(data),  header);
    },
    GetContact(data)
    {
         return axios.post(apiurltravel+"pphHome/GetContact", JSON.stringify(data),  header);
    },
    AddContact(data)
    {
         return axios.post(apiurltravel+"pphHome/AddContact", JSON.stringify(data),  header);
    },
    UpdateContact(data)
    {
         return axios.post(apiurltravel+"pphHome/UpdateContact", JSON.stringify(data),  header);
    },
    DeleteContact(data)
    {
         return axios.post(apiurltravel+"pphHome/DeleteContact", JSON.stringify(data),  header);
    },
    GetProductType(data)
    {
         return axios.post(apiurltravel_new+"admin/GetProductType", JSON.stringify(data),  header);
    },
    AddProductType(data)
    {
         return axios.post(apiurltravel_new+"admin/AddProductType", JSON.stringify(data),  header);
    },
    UpdateProductType(data)
    {
         return axios.post(apiurltravel_new+"admin/UpdateProductType", JSON.stringify(data),  header);
    },
    DeleteProductType(data)
    {
         return axios.post(apiurltravel_new+"admin/DeleteProductType", JSON.stringify(data),  header);
    },
    GetEcommerceMenuList(data)
    {
         return axios.post(apiurltravel_new+"admin/GetEcommerceMenuList", JSON.stringify(data),  header);
    },
    UpdateEcommerceMenu(data)
    {
         return axios.post(apiurltravel_new+"admin/UpdateEcommerceMenu", JSON.stringify(data),  header);
    },
    AddEcommerceMenu(data)
    {
         return axios.post(apiurltravel_new+"admin/AddEcommerceMenu", JSON.stringify(data),  header);
    },
    DeleteEcommerceMenu(data)
    {
         return axios.post(apiurltravel_new+"admin/DeleteEcommerceMenu", JSON.stringify(data),  header);
    },
    adminuserlist(data)
    {
        return axios.post(apiurltravel_new+"admin/userList_new", JSON.stringify(data),  header)
    },
    addUser(data)
    {
        return axios.post(apiurltravel_new+"admin/regUserNew", JSON.stringify(data),  header)
    },
    updateUser(data)
    {
        return axios.post(apiurltravel_new+"admin/editadminuser", JSON.stringify(data),  header)
    },
    deleteusermaster(data)
    {
        return axios.post(apiurltravel_new+"admin/deleteUser", JSON.stringify(data),  header)
    },
    dashboardcount(data)
    {
        return axios.post(apiurltravel_new+"admin/dashboardData", JSON.stringify(data),  header)
    },
    GetProducts(data)
    {
        return axios.post(apiurltravel_new+"admin/GetProducts", JSON.stringify(data),  header)
    },
   


}