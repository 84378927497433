export function authHeader() {
    let authToken = localStorage.getItem('token');
    let options= Headers = {
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Content-Type': 'application/json',
        headers: {
            Authorization: authToken,
            //Authorization: JSON.parse(authToken),
            // board_id:JSON.parse(localStorage.getItem('connection')),
            // created_by:JSON.parse(localStorage.getItem('login_user_id')),
            // updated_by:JSON.parse(localStorage.getItem('login_user_id'))
        }
    };
    return options;
}
export function authHeader_audit() {
    let authToken = localStorage.getItem('token');
    let options = Headers = {
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Content-Type': 'application/json',
        headers: {
            Authorization: JSON.parse(authToken),
            board_id:JSON.parse(localStorage.getItem('connection')),
            created_by:JSON.parse(localStorage.getItem('login_user_id')),
            updated_by:JSON.parse(localStorage.getItem('login_user_id')),
            audit:1
        }
    };
    return options;
}

export function authFileHeader() {
    let authToken = localStorage.getItem('token');
    let options_file = Headers = {
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Content-Type': 'multipart/form-data',
        headers: {
            Authorization: JSON.parse(authToken),
            board_id:JSON.parse(localStorage.getItem('connection')),
            created_by:JSON.parse(localStorage.getItem('login_user_id')),
            updated_by:JSON.parse(localStorage.getItem('login_user_id'))


        }
    };
    return options_file;
}
export function authHeadermain() {
    let authToken = localStorage.getItem('token');
    let options = Headers = {
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Content-Type': 'application/json',
        headers: {
            Authorization: JSON.parse(authToken),
            // board_id:JSON.parse(localStorage.getItem('connection'))
        }
    };
    return options;
}