<template><Toast />
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" :src="topbarImage()" />
			<!-- <span style="color:white">PPH</span> -->
		</router-link>
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<!-- <li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-calendar"></i>
					<span>Events</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-cog"></i>
					<span>Settings</span>
				</button>
			</li> -->
			<li>
				<button class="p-link layout-topbar-button" @click="toggleMenu">	
					<i class="pi pi-user"></i><br>
					<span>Profile</span>
					<Menu ref="menu" :model="overlayMenuItems" :popup="true" />
					
				</button>
				<br>{{name}}
				
			</li>
		</ul>
	</div>

	<Dialog
          v-model:visible="UpdateUserDialog"
		  :breakpoints="{'960px': '75vw'}"
          :style="{ width: '60vw' }"
          header="Edit Profile"
          :modal="true"
		  position="top"
		  @hide="reset"
        >
		<div class="col-12">
			<div class="p-fluid formgrid grid" style="margin-top: 2%;">
				<div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <InputText id="name" type="text" v-model="user_name"
                             />
                            <label for="name">Name</label>
                        </span>
                </div>
				<div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <InputText id="email" type="text" v-model="user_email"
                             />
                            <label for="email">Email</label>
                        </span>
                </div>
				<div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <InputText 
								id="mobile_no" 
								type="text" 
								onkeypress="return event.charCode >= 48 && event.charCode <= 57"
								maxlength="10"
								v-model="user_mobile"
                             />
                            <label for="mobile_no">Mobile No</label>
                        </span>
                </div>			
					
			</div>
		</div>
		<template #footer>
		<Button label="Save" @click="update_user" icon="pi pi-check" class="p-button-success"/>
		</template>
		</Dialog>

	<Dialog
          v-model:visible="productDialog"
          :style="{ width: '450px', width: '450px' }"
          header="Change Password"
          :modal="true"
          class="p-fluid"
        >

		 				<label for="password1" class="block text-900 font-medium text-xl mb-2">Old Password</label>
                        <Password id="password1" v-model="old_password" placeholder="Password" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem"></Password>
						 <label for="password1" class="block text-900 font-medium text-xl mb-2">New Password</label>
                        <Password id="password1" v-model="new_password" placeholder="Password" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem"></Password>
						 <label for="password1" class="block text-900 font-medium text-xl mb-2">Confirm Password</label>
                        <Password id="password1" v-model="conf_password" placeholder="Password" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem"></Password>
						<Button label="Change Password" class="p-button-rounded p-button-success" @click="change()" />
		</Dialog>
</template>

<script>
import apis from "@/apis";
export default {
	data() {
		
			return {
				user_name:"",
				user_email:"",
				user_mobile:"",
				conf_password: "",
				new_password: "",
				old_password: "",
				productDialog:false,
				UpdateUserDialog:false,
				user_data:{},
				name:"",
				overlayMenuItems: [
					{
						label: 'My Profile',
						icon: 'pi pi-save',
						// to:'/profile'
						command: () => {							
							this.UpdateUserDialog = true;
						}
					},
					{
						label: 'Change Password',
						icon: 'pi pi-pencil',
						command: () => {
							this.conf_password= "";
							this.new_password= "";
							this.old_password= "";
							this.productDialog = true;
							
          }
					},
					{
						label: 'Logout',
						icon: 'pi pi-sign-in',
						to:'/',
						// click: () => {
							command: () => {
						//	this.$session.destroy();
      				localStorage.clear();
      				this.$router.push("/");
            	return false;
          }
					},
					
				],
			}
	},
    methods: {
		async update_user()
		{
			let details={
				_id: this.user_data._id,
				full_name: this.user_name,
				email_id: this.user_email,
				mobile_number: this.user_mobile,
				gender: this.user_data.gender,
				user_type:this.user_data.user_type,
				address: this.user_data.address,
				status: this.user_data.status,
				dob:this.user_data.dob,
				updated_by: this.user_data.full_name,
			}
			console.log(details);
			return false
			var promise = apis.updateUser(details);
				promise.then((response) => {
					this.$swal(response.data.message);
					this.UpdateUserDialog = false;
				});
		},
		reset()
		{
			this.user_name=this.user_data.full_name;
			this.user_email=this.user_data.email_id;
			this.user_mobile=this.user_data.mobile_number;
		},
		change()
		{			
			if (!this.old_password) {
				this.$toast.add({
				severity: "error",
				summary: "error",
				detail: "Please Enter Old password",
				life: 3000,
				});
				return false;
			}
			if (!this.new_password) {
				this.$toast.add({
				severity: "error",
				summary: "error",
				detail: "Please Enter New password",
				life: 3000,
				});
				return false;
			}
			if (!this.conf_password) {
				this.$toast.add({
				severity: "error",
				summary: "error",
				detail: "Please Enter Confirm password",
				life: 3000,
				});
				return false;
			}
			if (this.conf_password != this.new_password) {
				this.$toast.add({
				severity: "error",
				summary: "error",
				detail: "Confirm Password Not Match With Password",
				life: 3000,
				});
				return false;
			}
			var uname="";
			if (localStorage.getItem("user_key")=='student') 
			{
				uname=localStorage.getItem('mobile');
			}
			else
			{
				uname=localStorage.getItem('email');
			}

				//api
				var data = {
				username: uname,
				password: this.new_password,
				type: localStorage.getItem('user_key'),
			};
			
			var promise = apis.passwordreset(data, this.id);
			promise.then((response) => {
			
				this.productDialog = false;
				this.$swal(response.data.message);
				this.update_pass_Dialog = false;
				localStorage.clear();
				this.$router.push("/"); 
			}).catch((error) => {
				//console.log(error);
				this.productDialog = false;
				this.$swal.fire(error.response.data);
				
			});

		},
		toggleMenu(event) {
			this.$refs.menu.toggle(event);
		},
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'pphlogo.png' : 'pphlogo.png';
		}
    },
		mounted(){
			this.name= localStorage.getItem("email");
			this.user_data = JSON.parse(localStorage.getItem("user_profile"));
			this.user_name=this.user_data.full_name;
			this.user_email=this.user_data.email_id;
			this.user_mobile=this.user_data.mobile_number;

			console.log("user Data", this.user_data);
		},
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>
<style scoped>
.layout-topbar .layout-topbar-logo {
   
    width: 150px;
   
}
</style>