<template>
	<div class="layout-footer">
		<img alt="Logo" :src="footerImage()" height="50" class="mr-2" />
		by
		<span class="font-medium ml-2">PPH</span> 
	</div>
</template>

<script>
	export default {
		name: "AppFooter",
		methods: {
			footerImage() {
				return this.$appState.darkTheme ? 'pphlogo.png' : 'pphlogo.png';
			}
		},
		computed: {
			darkTheme() {
				return this.$appState.darkTheme;
			}
		}
	}
</script>
<style>
.layout-topbar,.layout-sidebar{
	border-radius: 12px;
	background-color: var(--surface-overlay);
	/* box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08); */
  /* background-color:#455d80 !important; */
}
.layout-topbar .layout-topbar-button{
	color: black;
  /* color: white; */
}
.layout-menu li a{
	color: black;
  /* color: white; */
}
.layout-menu li a:hover{
  color:black;
}

body{
	background-color: #EFF3F8;
}
/* .layout-main-container{
    background-color: black !important;
}
.layout-wrapper{
    background-color: black !important;
} */
</style>
